<template>
  <div>

    <v-sheet :elevation="!single ? 3 : null"
             :key="card.id"
             :ref="card.id"
             :id="'card_' + card.id"
             :class="!single ? `wsRoundedHalf ma-3` : null"
             :width="!single ? '250' : null" >


      <!-- Card Header  -->
      <v-sheet v-if="!noHeader && !single" :color="getCardHeaderColor(card)" dark class="wsRoundedHalfTop px-3 py-2 d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <v-icon class="mr-1" small>mdi-account</v-icon>
          <h6 class="font-weight-medium">
            {{ getCardTypeText(card)  }}
            <v-sheet color="transparent" v-if="card.type === 'course'" :set="course = getCourse(card)" >
              {{ course.text }}
            </v-sheet>
          </h6>
        </div>
        <v-btn v-if="getCardDeletePermission(card)" @click="displayDeleteCard(card)" small icon><v-icon small>mdi-delete</v-icon></v-btn>
      </v-sheet>
      <!-- Card Content -->
      <div :class="!single ? 'pa-3' : null ">
        <!-- Card Image -->
        <div v-if="card.image" class="d-flex mb-3">
          <div>
            <v-hover v-slot="{hover}">
              <v-sheet class="wsRoundedHalf mt-2" >
                <v-img class="wsRoundedHalf" :height="card.type !== 'link' ? '150' : '100'"  width="223" :src="card.image" >
                  <v-sheet  v-if="hover && card.type !== 'link' " v-ripple
                            @click="displayDeleteImage(card)"
                            class="fill-height d-flex align-center justify-center"
                            :class="hover ? 'pointer' : ''"
                            color="#00000055" dark >
                    <v-icon small class="mr-1">mdi-delete</v-icon>
                    <h6 class="font-weight-medium">{{ $t('Delete') }}</h6>
                  </v-sheet>
                </v-img>
              </v-sheet>
            </v-hover>
          </div>
        </div>
        <!-- Card Course -->
        <div v-if="getCourse(card).img">
          <v-img class="wsRoundedHalf" height="150"  width="223" :src="getCourse(card).img" />
        </div>
        <!-- Course text-->
        <div v-if="getCourse(card).value">
          <h6>{{ getCourse(card).text }}</h6>
          <h6 class="font-weight-light">{{ getCourse(card).short_description }}</h6>
        </div>
        <!-- Card Message -->
        <div class="d-flex mb-3">
          <!-- Card Text-->
          <v-hover v-slot="{hover}">
            <v-sheet width="100%"  class="transition-ease-in-out pa-1 " style="border-radius: 5px" :color="hover ? wsLIGHTCARD : 'transparent'">
              <h5 contenteditable="plaintext-only"
                  @focusout="saveCardText($event , card); hover = false"
                  @focus="hover = true"
                  class="font-weight-medium mt-1 card-message transition-ease-in-out "
                  v-html="getCardText(card.text)" >
              </h5>
            </v-sheet>
          </v-hover>
        </div>

<!--        &lt;!&ndash; Card Buttons &ndash;&gt;-->
<!--        <v-sheet v-for="k in getButtonsRows(card.buttons)" :key="'button' +  j + k"-->
<!--                 :id=" i + '_' +card.id + '_' + 'button_row_' + k  "-->
<!--                 color="transparent">-->
<!--          <div class="d-flex just">-->
<!--            <v-sheet v-for="(button,l) in getButtonsRowColumns(card.buttons,k)"-->

<!--                     style="width: 100%"-->
<!--                     :key="'button' + i + j + k + l"-->
<!--                     class="py-1" color="transparent">-->
<!--              <v-hover v-slot="{hover}">-->

<!--                &lt;!&ndash; Button&ndash;&gt;-->
<!--                <v-sheet-->
<!--                    @click="displayEditButton(card , button , l )"-->
<!--                    :color="wsLIGHTCARD"-->
<!--                    max-width="270"-->
<!--                    style="border-radius : 5px ; "-->
<!--                    class="pointer fill-height "-->
<!--                    v-ripple-->
<!--                    block  elevation="0">-->

<!--                  <v-sheet :color="!hover ? 'transparent' : '#00000022'"-->
<!--                           style="border-radius : 5px ;transition: all 0.3s ease  "-->
<!--                           class="py-1 d-flex fill-height "-->
<!--                           :class="button.action !== 'url' ? 'justify-center align-center' : 'justify-space-between align-baseline' ">-->

<!--                    <div v-if="button.action === 'url' "></div>-->
<!--                    <h5 class="font-weight-medium text-center">{{ button.text }}</h5>-->
<!--                    <v-icon v-if="button.action === 'url' " x-small>mdi-arrow-top-right</v-icon>-->

<!--                  </v-sheet>-->

<!--                </v-sheet>-->

<!--              </v-hover>-->
<!--            </v-sheet>-->
<!--          </div>-->
<!--        </v-sheet>-->

<!--        &lt;!&ndash; Courses List Buttons &ndash;&gt;-->
<!--        <div v-if="card.type === 'courses'" class="mb-3">-->

<!--          <v-sheet  v-for="course in getActiveCourses()" :key="course.value"-->
<!--                    :color="wsLIGHTACCENT"-->
<!--                    max-width="270"-->
<!--                    style="border-radius : 5px ; "-->
<!--                    class="py-1 d-flex mx-1 mt-2 justify-center fill-height align-center"-->
<!--                    v-ripple-->
<!--                    elevation="0">-->
<!--            <h6 class="font-weight-medium text-center">{{ course.text }}</h6>-->
<!--          </v-sheet>-->

<!--        </div>-->

        <!-- Add Element Button -->
        <v-btn :color="wsACCENT"
               @click="displayAddElement('image',  card)"
               class="noCaps px-0 d-flex justify-start mt-3"
               block text >
          <v-icon class="mr-2"  >mdi-paperclip</v-icon>
          {{ $t('Add') }}
          <span></span>
        </v-btn>

      </div>


    </v-sheet>

    <!-- Add Image Dialog-->
    <wsDialog v-model="displayImageDialog"
              :title="$t('AddImage')"
              :show-save="false"
              :loading="LOADING"
    >
      <ws-file-uploader
          @success="addNewImage"
          public
          image
          browser
      />
    </wsDialog>

    <!-- Delete image Dialog-->
    <wsDialog v-model="displayDeleteCardImageDialog"
              :title="$t('DeleteMessageImage')"
              :show-save="false"
              :confirm-delete-text="$t('DeleteMessageImageConfirmation')"
              display-confirm-delete
              close-delete
              @delete="deleteCardImage"
              :loading="LOADING" />

  </div>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "telegramCard",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    noHeader : {
      type : Boolean,
      default : false
    },
    single : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      card : {
        type : 'card',
        text : 'enter text'
      },
      entityData : {},
      newButton : false,
      newImage : false,
      displayDeleteCardDialog : false,
      bufferCard : {},
      coursesSelect : [],
      displayButtonDialog : false,
      displayImageDialog : false,
      displayDeleteCardImageDialog : false,
    }
  },
  watch : {
    value : {
      handle() {
        if ( JSON.stringify(this.value) !== JSON.stringify(this.card) ) {
          this.card = Object.assign({} , this.value )
        }
      },
      deep : true
    },

    card : {
      handle() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.card)) {
          this.$emit('input', Object.assign({}, this.card))
        }
      },
      deep : true
    },
  },
  methods : {

    ...mapActions('chatbots', [
      'GET_CHATBOTS_CARDS',
      'EDIT_CARD',
      'DELETE_CARD',
      'ADD_CARD_BUTTON',
      'DELETE_CARD_BUTTON',
    ]),

    async deleteCardImage() {
      this.bufferCard.image = null
      this.card.image = null
      await this.EDIT_CARD( this.bufferCard )
      this.$emit('input' , this.card)
      this.displayDeleteCardImageDialog = false
    },


    async addNewImage(file) {
      this.bufferCard.image = file.url
      this.card.image = file.url
      this.$emit('input' , this.card)
      this.EDIT_CARD( this.bufferCard )
      this.displayImageDialog = false
    },


    getCardHeaderColor(card) {
      if ( card.is_root ) { return this.wsACCENT }
      switch(card.type) {
        case null : return this.wsDARKLIGHT
        default : return this.wsATTENTION
      }
    },
    getAddElements(card) {
      let items = [
        { text : this.$t('Button') , value : 'button' },
      ];

      if ( !card.image ) {
        items.push({ text : this.$t('Image')  , value : 'image'  })
      }

      return items

    },
    getCardTypeText(card) {

      if ( card.is_root ) { return this.$t('MainMenu') }

      switch( card.type ) {
        case null : return this.$t('Message');
        case 'link' : return this.$t('BotLinkAccount');
        case 'courses' : return this.$t('CoursesList');
        case 'course' : return this.$t('Course_');
        case 'student_study' : return this.$t('StudentStudy');
        case 'business_lead_default' : return this.$t('ConsultationRequest')
        default : return card.type
      }

    },
    getCardDeletePermission(card) {
      if ( card.is_root ) { return false }
      if ( card.buttons ) {
        let cardButtons = card.buttons.filter(el => el.action === 'card')
        if ( cardButtons.length > 0 ) {
          return false
        }
      }
      return true
    },
    getCourse(card) {

      if ( this.coursesSelect.length === 0 || card.type !== 'course' ) {
        return {}
      }

      let course = this.coursesSelect.find(el=> el.value === card.data )

      return course ? course : {}

    },
    getActiveCourses() {
      let items = []
      if ( this.coursesSelect.length  > 0 ) {
        items = this.coursesSelect.filter( el=> el.active)
      }
      return items
    },

    saveCardText( $event,  card ) {
      card.text = $event.target.innerHTML
          .replace(/&nbsp;/g, "")
          .replace(/<br>/g, "\n")
      this.card.text = card.text
      this.$emit('input' , JSON.parse(JSON.stringify(this.card)))
     this.EDIT_CARD( card )
    },
    getCardText(html) {
      return html.replace(/\n/g, "<br>")
    },
    getButtonsRows(buttons) {
      if ( !buttons ) {
        return 0
      }
      let rows = buttons.map(el=>el.row)

      return Math.max(...rows) + 1
    },
    getButtonsRowColumns(buttons , row ) {
      if ( !buttons ) {
        return 0
      }
      return buttons.filter(el=>el.row === row -1 )

    },
    displayAddElement($event  , card) {

      if ( $event === 'button') {
        this.displayNewButton( card)
      }
      if ( $event === 'image') {
        this.displayAddImage( card )
      }

    },
    displayNewButton( column , card) {
      this.entityData = {
        type : 'card',
        text : this.$t('NewButtonText'),
        new_row : true
      }
      this.newButton = true
      this.displayButtonDialog = true
      this.bufferCard = card
      this.card = card
    },
    displayAddImage(card) {
      //
      this.bufferCard = card
      this.entityData = {}
      this.newImage = true
      this.displayImageDialog = true


    },
    displayEditButton( card , button , l) {
      this.bufferCard = card
      this.newButton = false
      this.entityData = button
      this.displayButtonDialog = true
      this.selectetButtonColumnIndex = l
    },
    displayDeleteImage( card ) {
      this.displayDeleteCardImageDialog = true
      this.bufferCard = card
    },
    displayDeleteCard( card ) {
      this.displayDeleteCardDialog = true
      this.bufferCard = card
    },
    getNewRowDisplayCondition() {

      if ( !this.bufferCard.buttons ) {
        return false
      }
      if ( this.bufferCard.buttons.filter( el=> el.row === this.bufferCard.buttons[this.bufferCard.buttons.length -1].row ).length  > 2 ) {
        return false
      }

      return true
    },

  },
  mounted() {
    if ( this.value.uuid ) {
      this.card = Object.assign({} , this.value )
    }
  }
}
</script>

<style scoped>
.card-message  {
  line-height: 1.4;
  overflow-wrap: break-word;
  inline-size: 220px;
  outline: none;
}
</style>