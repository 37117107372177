<template>
  <v-fade-transition>
    <v-sheet :color="wsACCENT + '55'" class="telegram-dialog" v-if="display" >
      <v-expand-transition>
        <div v-if="display" class="d-flex justify-center align-center fill-height ">
          <div class="d-flex">



            <!-- Mobile Device-->
            <v-sheet class="wsRounded pa-5" color="grey darken-4" height="600" min-height="350" min-width="350" width="350" dark>
                <v-row class="no-gutters flex-wrap flex-column fill-height " :class="!compact ? 'pb-6' : ''">

                  <!-- Header -->
                  <v-col cols="auto" class="shrink" >
                    <v-sheet dark class="wsRoundedHalfTop" :color="wsACCENT">
                      <div class="d-flex justify-space-between align-center pt-1 px-3 ">
                        <h6>12:00</h6>
                        <div class="d-flex">
                          <v-icon class="mr-1" x-small>mdi-google-analytics</v-icon>
                          <v-icon class="mr-1" x-small>mdi-wifi</v-icon>
                          <v-icon class="mr-1" x-small>mdi-battery-80</v-icon>
                        </div>

                      </div>


                      <div v-if="newsletter.is_published" class="d-flex align-center justify-space-between pb-2 ">
                        <div>
                        </div>
                        <div>
                          <h5 class="text-center">{{telegramBot.first_name }}</h5>
                          <h6 class="grey--text font-weight-light text--lighten-3 text-center">bot</h6>
                        </div>
                        <v-avatar class="mr-3 pointer mt-1" size="28" :color="!telegramBot.img ? wsATTENTION : ''">
                          <v-img  v-if="telegramBot.img" :src="telegramBot.img"></v-img>
                          <h5 v-else-if="telegramBot.first_name" style="color : white" >{{ telegramBot.first_name.substring(0, 1)  }}</h5>
                        </v-avatar>
                      </div>
                      <div v-else-if="!editDesign" class="px-3 pb-2">
                        <h5 class="text-center">{{ $t('Receivers') }}</h5>
                        <h6 class="grey--text font-weight-light text--lighten-3 text-center">{{ $t('NewMessage') }}</h6>
                      </div>
                      <div v-else class="d-flex align-center justify-space-between pb-2 ">
                        <div>
                          <v-btn @click="editDesign = false" icon>
                            <v-icon  :color="wsLIGHTACCENT">mdi-chevron-left</v-icon>
                          </v-btn>
                        </div>
                        <div>
                          <h5 class="text-center">{{telegramBot.first_name }}</h5>
                          <h6 class="grey--text font-weight-light text--lighten-3 text-center">bot</h6>
                        </div>
                        <v-avatar class="mr-3 pointer mt-1" size="28" :color="!telegramBot.img ? wsATTENTION : ''">
                          <v-img  v-if="telegramBot.img" :src="telegramBot.img"></v-img>
                          <h5 v-else-if="telegramBot.first_name" style="color : white" >{{ telegramBot.first_name.substring(0, 1)  }}</h5>
                        </v-avatar>
                      </div>



                    </v-sheet>
                  </v-col>
                  <!-- Content -->
                  <v-col cols="auto" class="grow" style="height: 100px"  >
                    <v-sheet class="fill-height overflow-y-auto " light>
                      <div >

                        <div v-if="newsletter.is_published">
                          <h4 class="mt-5 text-center align-center">{{ $t('NewsletterPublished') }}</h4>
                        </div>
                        <div class="px-3" v-else-if="displaySendConfirm">
                          <h3 class="mt-10 text-center font-weight-light">{{ $t('EverythingIsReady') }}! </h3>
                          <h5 class="mb-10 text-center ">{{ $t('ConfirmNewsletterPublishMessage') }} </h5>
                          <v-btn @click="publishLetter" block rounded dark class="noCaps" :color="wsATTENTION">
                            {{ $t('Send') }}
                          </v-btn>
                        </div>
                        <div v-else-if="!editDesign" >

                          <div class="px-3 py-3 d-flex justify-space-between">
                            <!-- icons and name-->
                            <div class="d-flex">
                              <v-icon size="20" :color="newsletter.total_receivers > 0 ? wsATTENTION : 'grey'" class="mr-3">mdi-account-multiple</v-icon>
                              <div>
                                <h4 style="font-size: 16px; font-weight: 600"  >{{ $t('newsletter.email.to') }}</h4>
                                <h5 v-if="newsletter.total_receivers === 0" class="font-weight-light grey--text text--darken-3">
                                  {{$t('newsletter.email.toDescriptionEmpty') }}
                                </h5>
                                <div v-else>
                                  <h5  class="wei grey--text text--darken-3">
                                    {{  ($t('newsletter.email.toDescription') + ' : ' + newsletter.total_receivers ) }}
                                  </h5>
                                </div>

                              </div>
                            </div>
                          </div>

                          <v-divider :style="`border-color : ${wsBACKGROUND}`"></v-divider>

                          <ws-data-table
                              v-if="newsletter.receivers_group.length > 0"
                              :items="newsletter.receivers_group"
                              :headers="headers"
                              dense
                          >
                            <template #item.name="{item}">
                              <h5 :style="`color : ${item.count ? wsATTENTION : 'grey'}`">
                                {{item.name}}
                              </h5>
                            </template>
                            <template #item.delete="{item}">
                              <v-btn @click="deleteReceiversGroup(item)" :color="wsACCENT" icon>
                                <v-icon small>mdi-delete</v-icon>
                              </v-btn>
                            </template>

                          </ws-data-table>
                          <div class="px-3" v-if="!checkFilters('all')">

                            <h4 class="mt-5 text-center">{{ $t('newsletter.email.receiver_groups.add_group') }}: </h4>


                            <!-- Receivers group Type-->
                            <ws-select
                                v-model="entityDataReceivers.type"
                                @input="changeGroupType"
                                class="mt-5"
                                :placeholder="$t('Choose')"
                                :items="receiversGroupTypeSelect"
                                :label="$t('newsletter.email.receiver_groups.title')"
                            />
                            <!-- Course -->
                            <v-expand-transition>
                              <div v-if="entityDataReceivers.type === 'course' ">
                                <ws-select
                                    v-if="entityDataReceivers.type === 'course'  && coursesSelectFiltered.length > 0"
                                    @input="changeCourse"
                                    v-model="entityDataReceivers.course_id"
                                    :items="coursesSelectFiltered"
                                    :error="courseError"
                                    class="mt-5"
                                    :label="$t('Course_')"
                                />
                              </div>
                            </v-expand-transition>

                            <v-expand-transition>
                              <ws-select
                                  v-if="filtersSelect.length > 0"
                                  v-model="entityDataReceivers.filter"
                                  class="mt-5"
                                  :items="filtersSelect"
                                  :label="$t('Filter')"
                                  clearable
                              />

                            </v-expand-transition>

                            <!-- Course : Payment Plan -->
                            <v-expand-transition>
                              <ws-select
                                  v-if="entityDataReceivers.type === 'course' && paymentPlansSelect.length > 0 "
                                  v-model="entityDataReceivers.payment_plan_id"
                                  class="mt-5"
                                  :items="paymentPlansSelect"
                                  :label="$t('PaymentPlan')"
                                  clearable
                              />
                            </v-expand-transition>
                            <!-- Course : Enrollment Wave -->
                            <v-expand-transition>
                              <ws-select
                                  v-if="entityDataReceivers.type === 'course' && enrollmentWavesSelect.length > 0 "
                                  v-model="entityDataReceivers.schedule_id"
                                  class="mt-5"
                                  :items="enrollmentWavesSelect"
                                  :label="$t('EnrollmentWave')"
                                  clearable
                              />
                            </v-expand-transition>
                            <!-- Course : Group -->
                            <v-expand-transition>
                              <ws-select
                                  v-if="entityDataReceivers.type === 'course' && groupsSelect.length > 0 "
                                  v-model="entityDataReceivers.group_id"
                                  class="mt-5"
                                  :items="groupsSelect"
                                  :label="$t('Group')"
                                  clearable
                              />
                            </v-expand-transition>

                            <v-btn block :disabled="!entityDataReceivers.type" @click="addReceiversGroup" rounded class="noCaps px-6 my-5" dark :color="wsACCENT">{{ $t('Add') }}</v-btn>

                          </div>
                        </div>
                        <div class="px-6 pt-5" v-else>
                          <div  class="d-flex justify-space-between mb-3">
                            <h6 :style="`color : ${wsACCENT}`" >{{ telegramBot.first_name }}</h6>
                            <h6>12:00</h6>
                          </div>

                          <telegram-card
                              @input="newsletter.root_card = $event"
                              v-model="newsletter.root_card"
                              single
                          />

                        </div>

                      </div>
                    </v-sheet>
                  </v-col>
                  <!-- Footer -->
                  <v-col cols="auto" class="shrink" >
                    <v-sheet v-if="!newsletter.is_published" :color="wsLIGHTCARD" light class="wsRoundedHalfBottom d-flex  fill-height ">
                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-btn v-if="editDesign" block @click="backAction" text  :color="wsACCENT" class="noCaps justify-start">
                            <v-icon>mdi-chevron-left</v-icon>
                            {{ $t('GoBack') }}

                          </v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                              v-if="!displaySendConfirm"
                              @click="nextAction"
                              :color="!editDesign ? wsACCENT : wsATTENTION"
                              :disabled="newsletter.total_receivers === 0"
                              :text="!editDesign"
                              :dark="editDesign"
                              class="noCaps justify-end"
                              elevation="0"
                              block
                          >
                            {{ !editDesign ?  $t('Next') : $t('Send') }}
                            <v-icon class="ml-1">mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>

                </v-row>
            </v-sheet>

            <v-btn dark :style="`background-color: ${wsACCENT}`" class="mr-n10 ml-4"  @click="display = false" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>


          </div>
        </div>
      </v-expand-transition>
    </v-sheet>
  </v-fade-transition>

</template>

<script>
import {mapActions, mapState} from "vuex";
import telegramCard from "@/components/pages/businessDashboard/businessNewsletter/telegram/UI/telegramCard";
export default {
  name: "emailNew",
  components: {
    telegramCard
  },

  props : {
    value : {
      type : Boolean,
      default : false
    },
    newsletter : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {

      card : {
        type : 'card',
        text : 'Enter Text'
      },
      displaySendConfirm : false,
      display : false,
      coursesSelect : [],
      entityData : {},
      entityDataReceivers : {},
      editReceivers : false,
      editSubject : false,
      editDesign : false,
      courseError : false,
    }
  },
  computed : {
    ...mapState('newsletter' , ['telegram' , 'telegramBot']),

    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        // { text : this.$t('Receivers') , value : 'count' },
        { value : 'delete' , width : 10 }
      ]
    },
    selectedCourse() {
      let course = {}
      if ( this.entityDataReceivers.course_id ) {
        course = this.coursesSelect.find( el=> el.value === this.entityDataReceivers.course_id )
      }
      return course
    },

    receiversGroupTypeSelect() {
      let items = [
        { text : this.$t('newsletter.email.receiver_groups.type.all')       , value : 'all'        },
        { text : this.$t('Course_')                                          , value : 'course'   },
        { text : this.$t('newsletter.email.receiver_groups.type.all_users') , value : 'all_users'  },
        { text : this.$t('newsletter.email.receiver_groups.type.clients')   , value : 'clients'    },
       { text : this.$t('Leads')   , value : 'leads'  },
        { text : this.$t('newsletter.email.receiver_groups.type.managers')  , value : 'managers'   },
        // { text : this.$t('newsletter.email.receiver_groups.type.manual_email_list') , value : 'manual_email_list'   },

      ];
      items = items.filter(el => {
       if ( this.checkFilters(el.value) ) { return false }

       if ( el.value === 'leads' && this.leadsFilters.length === 0 ) {
        return false
       }
       if ( el.value === 'course' && ( this.coursesSelectFiltered.length === 0 || this.checkFilters('all_users') || this.checkFilters('clients') ) ) {
         return false
       }

       return true

      })

      return items
    },
    coursesSelectFiltered() {

      if ( this.checkFilters('all_users') || this.checkFilters('clients') ) {
        return []
      }

      let items = this.coursesSelect
      if ( items.length === 0 ) {
        return []
      }
      items = items.filter(el=> {
        if (this.checkFilters('course' , null,el.value) ) {
          return false
        }

        if ( el.value === this.entityDataReceivers.course_id && this.courseFilters.length === 0 ) {
          return false
        }

        return true
      } )
      return items
    },

    courseFilters() {
      let items = []
      items = [
        { text : this.$t('newsletter.email.receiver_groups.filter.active_and_completed') , value : 'active_and_completed' },
        { text : this.$t('newsletter.email.receiver_groups.filter.in_process')           , value : 'in_process'           },
        { text : this.$t('newsletter.email.receiver_groups.filter.completed')            , value : 'completed'            },
        { text : this.$t('newsletter.email.receiver_groups.filter.not_active')           , value : 'not_active'           },
      ]
      if ( this.entityDataReceivers.course_id ) {
        items = items.filter( el=> !this.checkFilters('course', el.value, this.entityDataReceivers.course_id ))
      }

      return items

    },
    leadsFilters() {
      let items = []
      items = [
        { text : this.$t('lead_new_single') , value : 'new' },
        { text : this.$t('lead_contacted')  , value : 'contacted' },
        { text : this.$t('lead_no_contact') , value : 'no_contact' },
        { text : this.$t('lead_in_process') , value : 'in_process' },
        { text : this.$t('lead_success')    , value : 'success' },
        { text : this.$t('lead_refuse')     , value : 'refuse' },
      ]
      items = items.filter( el=> !this.checkFilters('leads', el.value, ))

      return items

    },
    filtersSelect() {
      let items = []
      if ( this.entityDataReceivers.type === 'course') {
        items = this.courseFilters
      }
      if ( this.entityDataReceivers.type === 'leads') {
        items = this.leadsFilters
      }
      return items
    },
    enrollmentWavesSelect() {
      let items = []
      if ( this.selectedCourse.enrollment_waves ) {
        items = this.selectedCourse.enrollment_waves
      }
      if ( items.length > 0 ) {
        items = items.filter( el=> !this.checkFilters(
            'course', this.entityDataReceivers.filter,
            this.entityDataReceivers.course_id ,
            el.value,
            this.entityDataReceivers.payment_plan_id,
        ))
      }
      return items
    },
    groupsSelect() {
      let items = []
      if ( this.selectedCourse.groups ) {
        items = this.selectedCourse.groups
        if ( this.enrollmentWavesSelect.length > 0 ) {
          if ( !this.entityDataReceivers.schedule_id ) {
            return []
          }
          items = items.filter(el => el.enrollment_wave_id === this.entityDataReceivers.schedule_id )
        } else {
          items = items.filter(el => !el.enrollment_wave_id )
        }
      }

      if ( items.length > 0 ) {
        items = items.filter( el=> !this.checkFilters(
            'course', this.entityDataReceivers.filter,
            this.entityDataReceivers.course_id ,
            this.entityDataReceivers.schedule_id,
            this.entityDataReceivers.payment_plan_id,
            el.value
        ))
      }

      return items
    },
    paymentPlansSelect() {
      let items = []
      if ( this.selectedCourse.payment_plans ) {
        items = this.selectedCourse.payment_plans
      }
      if ( items.length > 0 ) {
        items = items.filter( el=> !this.checkFilters(
            'course', this.entityDataReceivers.filter,
            this.entityDataReceivers.course_id ,
            this.entityDataReceivers.schedule,
            el.value))
      }
      return items
    },
    selectedGroup() {
      return this.checkFilters(
          this.entityDataReceivers.type,
          this.entityDataReceivers.filter,
          this.entityDataReceivers.course_id,
          this.entityDataReceivers.schedule_id,
          this.entityDataReceivers.payment_plan_id,
          this.entityDataReceivers.group_id,
      )
    },

  },
  watch : {
    display() {
      if ( this.display !== this.value ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.display !== this.value ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('newsletter' , [
        'EDIT_NEWSLETTER',
        'ADD_NEWSLETTER_GROUP',
        'DELETE_NEWSLETTER_GROUP',
        'GET_COURSES_SELECT',
        'PUBLISH_TELEGRAM_NEWSLETTER'
    ]),

    backAction() {
      if ( this.displaySendConfirm) {
        this.editDesign = true
        this.displaySendConfirm = false
        return
      }
      if ( this.editDesign) {
        this.editDesign = false
        return
      }

    },
    async publishLetter() {

      let result = await this.PUBLISH_TELEGRAM_NEWSLETTER(this.newsletter.uuid)
      if ( !result ) {
        return
      }
      this.newsletter.is_published = true
      this.notify(this.$t('NewsletterPublished') , 'success')
      this.$emit('publish')
    },

    nextAction() {

      if (!this.editDesign ) {
        this.editDesign = true
      } else {
        this.displaySendConfirm = true
      }

    },

    updateNewsletterDesign($event)  {
      this.newsletter.newsletter_design = $event
      this.$emit('update' , this.newsletter)
    },
    changeGroupType() {
      this.courseError = false
      delete this.entityDataReceivers.filter
      delete this.entityDataReceivers.filter_name
      delete this.entityDataReceivers.course_id
      delete this.entityDataReceivers.payment_plan_id
      delete this.entityDataReceivers.schedule_id
      this.entityDataReceivers = Object.assign({} , this.entityDataReceivers)
    },
    changeCourse() {
      this.courseError = false
      delete this.entityDataReceivers.payment_plan_id
      delete this.entityDataReceivers.schedule_id
      delete this.entityDataReceivers.group_id
      this.entityDataReceivers = Object.assign({} , this.entityDataReceivers)
    },
    async editNewsletter() {
      let result = await this.EDIT_NEWSLETTER(this.entityData)
      if ( !result ) {
        return
      }
      this.newsletter.subject = this.entityData.subject
      this.newsletter.text = this.entityData.text

      this.editSubject = false
    },
    async addReceiversGroup() {
      if ( this.entityDataReceivers.type === 'course' && !this.entityDataReceivers.course_id ) {
        this.courseError = true
        this.notify(this.$t('SelectCourse' ), 'warning')
      }

      this.entityDataReceivers.newsletter_id = this.newsletter.uuid
      this.entityDataReceivers.name = this.getGroupTypeName(this.entityDataReceivers.type)
      this.entityDataReceivers.filter_name = this.getFilterName(this.entityDataReceivers.filter)

      let result = await this.ADD_NEWSLETTER_GROUP(this.entityDataReceivers)
      if ( !result || result === true ) {
        return
      }
      this.newsletter.total_receivers = result.newsletter_total_receivers
      this.entityDataReceivers = {}
      delete result.newsletter_total_receivers
      this.newsletter.receivers_group.push(result)

    },
    async deleteReceiversGroup(item) {

      let result = await this.DELETE_NEWSLETTER_GROUP(item.uuid)
      if ( !result ) {
        return
      }
      this.newsletter.total_receivers = result.newsletter_total_receivers
      let index = this.newsletter.receivers_group.findIndex( el => el.uuid === item.uuid )
      if ( index === -1 ) {
        return
      }
      this.newsletter.receivers_group.splice(index , 1)
      this.entityDataReceivers = {}

    },

    //technical

    checkFilters(type = null,filter = null,course = null,schedule = null,paymentPlan = null,group = null) {

      type = !type ? null : type
      filter = !filter ? null : filter
      course = !course ? null : course
      schedule = !schedule ? null : schedule
      paymentPlan = !paymentPlan ? null : paymentPlan
      group = !group ? null : group

      let item = this.newsletter.receivers_group.find(el => {
        let check = true
        if ( el.type !== type) { check = false }
        if ( el.filter !== filter) { check = false }
        if ( el.course_id !== course) { check = false }
        if ( el.schedule_id !== schedule) { check = false }
        if ( el.payment_plan_id !== paymentPlan) { check = false }
        if ( el.group_id !== group) { check = false }
        return check
      })

      return item
    },

    getGroupIcon(type) {
      switch(type) {
        case 'all' : return 'mdi-account-group-outline'
        case 'all_users' : return 'mdi-account-multiple'
        case "clients" : return "mdi-card-account-details-outline"
        case "managers" : return "mdi-account-tie"
        case 'course' : return 'mdi-school-outline'
        case 'leads' : return 'mdi-card-account-phone-outline'
        default : return 'mdi-cog'
      }
    },
    getGroupTypeName(type) {

      let item = this.receiversGroupTypeSelect.find(el => el.value === type)
      return item ? item.text : ''
    },
    getFilterName(type) {
      let item = this.filtersSelect.find(el => el.value === type)
      return item ? item.text : ''
    }
  },
  async mounted() {
    if ( this.value ){
      this.display = this.value
    }
    this.entityData = Object.assign({} , this.newsletter)
    if ( this.newsletter.root_card ) {
      this.card = this.newsletter.root_card
    }
    this.coursesSelect = await this.GET_COURSES_SELECT()
    if (this.newsletter.receivers_group && this.newsletter.receivers_group.length === 0 ) {
      this.editReceivers = true
    }
  }
}
</script>

<style scoped>
.telegram-dialog {
  position: fixed;
  top : 0;
  bottom:0;
  left : 0;
  right : 0;
}
</style>