<template>
  <sub-page
      :title="$t('Telegram')"
      subtitle="Telegram"
      icon="mdi-send"
      :header-action="openNewNewsletter"
      :header-action-text="$t('AddNewsletter')"
      v-model="$store.state.newsletter.pageData"
      header-route="newsletter"
  >

    <template #default>
      <div class="pa-16 d-flex justify-center align-center" v-if="loading">
        <v-progress-circular class="mt-16" :color="wsACCENT" size="72" />
      </div>
      <v-sheet v-else-if="$store.state.newsletter.telegram" class="wsRoundedHalf mt-6 ">
        <ws-data-table
            :items="items"
            :headers="headers"
            hide-default-header
            class="wsHoverLight"

        >
          <template v-slot:item="{item}">
            <tr class="pointer" @click="openNewsletter(item)">
              <td>
                <router-link
                    :is="item.is_single_message ? 'span' : 'router-link'"
                    :to="businessDashLink('newsletter/telegram/view/' + item.uuid)"
                    :style="`color : ${wsACCENT}`"
                    class="linkHover noUnderline"
                >
                  <h5>{{ item.name }}</h5>
                </router-link>

              </td>
              <td width="10px" class="text-no-wrap">
                <ws-chip v-if="item.receivers_type" icon="mdi-account-multiple" :text="$t('receivers_' + item.receivers_type)"></ws-chip>
              </td>
              <td width="10px" class="text-no-wrap">
                <wsChip v-if="item.date_published" icon="mdi-calendar" :text="PARSE_TIME_STAMP(item.date_published)"></wsChip>
              </td>
              <td width="10px" >
                <v-chip small outlined :color="!item.is_published ? 'grey' : wsACCENT ">
                  {{ item.is_published ? $t('Published') : $t('Draft')  }}
                </v-chip>
              </td>
              <td class="text-no-wrap">
<!--                <v-btn @click.stop="copyNewsletter(item)" :color="wsACCENT" icon>-->
<!--                  <v-icon>mdi-content-copy</v-icon>-->
<!--                </v-btn>-->
                <v-btn @click.stop="openDelete(item)" :color="wsACCENT" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </ws-data-table>
      </v-sheet>
      <v-sheet class="ma-16" v-else>
        <h3 class="font-weight-light text-center"> {{ $t('PleaseConnectTelegramChatBot')}}</h3>
        <div class="mt-6 d-flex justify-center">
          <v-btn :to="businessDashLink('chatbots')" :color="wsATTENTION" class="noCaps" dark>
            {{ $t('ChatBots') }}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>

      </v-sheet>

    </template>

    <template #dialog>
      <wsDialog
          v-model="displayDeleteDialog"
          :title="$t('DeleteNewsletter')"
          :confirm-delete-text="$t('DeleteNewsletterDescription')"
          @delete="deleteNewsletter"
          close-delete
          display-confirm-delete
      />
      <wsDialog v-model="displayDialog"
                :title="$t('AddNewsletter')"
                @save="addNewsletter">

        <h5 class="mb-3">{{$t('Name')}}</h5>
        <ws-text-field
            v-model="entityData.name"
            @input="nameError = false"
            :placeholder="$t('Name')"
            :error="nameError"
            dense outlined
            class="mb-3"
        />

<!--        <ws-button-group-->
<!--            v-model="entityData.template_type"-->
<!--            :items="templateTypesSelect"-->
<!--            :label="$t('newsletter.email.email_design')"-->
<!--            :description="$t('newsletter.email.email_design_description')"-->
<!--            class=" mt-5"-->
<!--        />-->

<!--        <v-expand-transition>-->
<!--          <ws-select-->
<!--              v-if="entityData.template_type !== 'new'"-->
<!--              v-model="entityData.template"-->
<!--              :label="$t('Template')"-->
<!--              :placeholder="$t('SelectTemplate')"-->
<!--              :items="entityData.template_type === 'template' ? templatesSelect : newslettersSelect"-->
<!--              class="pt-5"-->
<!--              :autocomplete="(entityData.template_type === 'template' ? templatesSelect : newslettersSelect).length > 7"-->
<!--          />-->
<!--        </v-expand-transition>-->


      </wsDialog>
      <telegram-message-dialog
          v-if="displayTelegram"
          v-model="displayTelegram"
          :newsletter="selectedNewsletter"
          @publish="initPage"
      />
    </template>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";
import telegramMessageDialog
  from "@/components/pages/businessDashboard/businessNewsletter/telegram/viewNewsletter/telegramMessageDialog";

export default {
  name: "newslettersTable",
  components : {
    telegramMessageDialog
  },
  data() {
    return {
      loading : false,
      displayTelegram : false,
      displayDeleteDialog : false,
      selectedNewsletter : {},
      displayTemplates : false,
      displayDialog : false,
      entityData : {  },
      defaultEntityData : {
        template_type : 'new'
      },
      items : [],
      templatesSelect: [],
      nameError : false
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name')          , value : 'name'          },
        { text : this.$t('ReceiversType') , value : 'receivers_type' },
        { text : this.$t('Published')     , value : 'date_published' },
        { text : this.$t('Status')        , value : 'is_published'   },
        { value : 'action' , sortable : false , width : 50 }
      ]
    },
    templateTypesSelect() {
      return [
        { text : this.$t('New') , value : 'new' },
        { text : this.$t('Template') , value : 'template' },
        { text : this.$t('newsletter.email.email_design_copy') , value : 'existing' },
      ]
    },
    newslettersSelect() {
      let items = []
      this.items.forEach(item => {
        items.push( {
          text : item.name,
          value : item.uuid
        })
      })
      return items
    }
  },
  methods : {
    ...mapActions('newsletter', [
        'GET_TELEGRAM_NEWSLETTERS',
        'GET_TELEGRAM_NEWSLETTER',
        'ADD_TELEGRAM_NEWSLETTER',
        // 'GET_BUSINESS_NEWSLETTER_TEMPLATES',
        'DELETE_TELEGRAM_NEWSLETTER'
    ]),

    ...mapActions('chatbots', [
      'GET_CHATBOTS_STATUS',
    ]),

    async openNewsletter(item) {
      if ( !item.is_single_message ) {
        await this.$router.push(this.businessDashLink('newsletter/telegram/view/' + item.uuid))
      } else {

        let result = await this.GET_TELEGRAM_NEWSLETTER(item.uuid)
        if ( !result || result === true) { return }

        this.selectedNewsletter = result
        this.displayTelegram = true
      }
    },

    openDelete(item) {
      this.selectedNewsletter = item
      this.displayDeleteDialog = true
    },
    deleteNewsletter() {
      let result = this.DELETE_NEWSLETTER(this.selectedNewsletter.uuid )
      if ( !result ) {
        return
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedNewsletter.uuid)
      if ( index === -1 ) {
        return
      }
      this.items.splice(index , 1 )
      this.displayDeleteDialog = false
      this.notify(this.$t('NewsletterDeleted'))

    },
    copyNewsletter(item) {
      this.console.log(this.$t('NewsletterCopied') + item)
    },

    async getTemplates() {
      if ( this.templatesSelect.length > 0 ) {
        return
      }
      let result = await this.GET_BUSINESS_NEWSLETTER_TEMPLATES()
      if ( !result || result === true ) {
        return
      }
      let items = []
      result.forEach(item => {
        items.push( { text : item.name , value : item.uuid })
      })
      this.templatesSelect = items
    },
    async addNewsletter() {
      if ( !this.entityData.name ) {
        this.nameError = true
        return this.notify(this.$t('EnterName') , 'warning')
      }
      let result = await this.ADD_TELEGRAM_NEWSLETTER(this.entityData)
      if ( !result ) { return }
      this.items.unshift(result)
      this.displayDialog = false
      this.notify(this.$t('NewsletterCreated') , 'success')
      this.openNewsletter(result)
    },

    openNewNewsletter() {
      // this.getTemplates()
      this.entityData = JSON.parse(JSON.stringify(this.defaultEntityData))
      this.displayDialog = true
    },

    async initPage() {

      this.loading = true
      this.$store.state.newsletter.emailNavigationSelect = this.navigationItems
      let telegramResult = await this.GET_CHATBOTS_STATUS()
      this.$store.state.newsletter.telegram = telegramResult.telegram ?? false
      this.$store.state.newsletter.telegramBot = telegramResult.telegram_bot || {}

      this.loading = false


      let result = await this.GET_TELEGRAM_NEWSLETTERS()
      if ( !result || result === true) { return }
      result.reverse()
      this.items = result
      this.displayDialog = false
    }

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>